import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { PricingService } from "../../../services/pricing.service";
import { ICheckoutRequest, IPaymentDetails, PaymentService } from "./payment.service";
import { OrganizationService } from "../../../services/organization.service";
import { Contact } from "../../../pojo/Contact";
import { EmailService } from "../../../services/email.service";
import { S25ItemI } from "../../../pojo/S25ItemI";
import { EventService } from "../../../services/event.service";
import { Debounce } from "../../../decorators/debounce.decorator";
import { S25EditableNumberComponent } from "../../s25-editable/s25-editable-number/s25.editable.number.component";
import { UserprefService } from "../../../services/userpref.service";
import { S25Datefilter } from "../../s25-dateformat/s25.datefilter.service";
import { S25LoadingApi } from "../../s25-loading/loading.api";
import { BalanceUpdateService } from "../pricing-org-table-components/s25-pricing-organization/balance.update.service";
import { IntegrationService, IntegrationTypes } from "../../integrations/integration.service";
import { jSith } from "../../../util/jquery-replacement";
import { S25Const } from "../../../util/s25-const";
import { TelemetryService } from "../../../services/telemetry.service";
import { Telemetry } from "../../../decorators/telemetry.decorator";
import { FlsService } from "../../../services/fls.service";

@TypeManagerDecorator("s25-ng-payments")
@Component({
    selector: "s25-ng-payments",
    template: ` <div [class.loading]="!init && currentView === 'history'">
            <s25-loading-inline [model]="{}"></s25-loading-inline>
        </div>

        <!-- Sidebar Form -->
        <ng-template #sideBarForm let-data="data">
            @if (remainingBalance > 0) {
                <p>Remaining Balance: {{ remainingBalance | currency }}</p>
                <p *ngIf="data.type !== 'FINAL'">
                    {{ (currentView === "history" ? "Payment " : "Request ") + "Amount:" }} {{ requestAmt | currency }}
                </p>
            }
            <label>
                Due Date
                <s25-datepicker
                    [modelValue]="data.dueDate"
                    (modelValueChange)="data.dueDate.date = $event"
                ></s25-datepicker>
            </label>
            <label *ngIf="currentView === 'history'">
                Description
                <input type="text" class="c-input" [(ngModel)]="data.description" />
            </label>
            <label>
                Payment Type
                <select
                    class="cn-form__control"
                    [(ngModel)]="data.type"
                    (ngModelChange)="processRequestAmt(null, data.prop)"
                >
                    <option value="DEPOSIT">Deposit</option>
                    <option value="FINAL">Balance</option>
                    <option value="MISC">Misc</option>
                </select>
            </label>
            <label *ngIf="currentView === 'history'">
                Payment Status
                <select class="cn-form__control" [(ngModel)]="data.paymentStatus">
                    <option value="paid">Paid</option>
                    <option value="unpaid">Unpaid</option>
                </select>
            </label>
            <label *ngIf="currentView === 'history'">
                Replacing Voided Id
                <input type="text" class="c-input" [(ngModel)]="manualPaymentData.replacementId" />
            </label>
            <label *ngIf="sources.length > 1 && currentView === 'request'"
                >Payment Processor:&nbsp;
                <select
                    class="cn-form__control"
                    [ngModel]="data.source"
                    (ngModelChange)="onSelectedSourceChange($event)"
                >
                    <option value="null">Select Processor</option>
                    <option [ngValue]="source" *ngFor="let source of sources">{{ source.name }}</option>
                </select>
            </label>
            <div *ngIf="data.type !== 'FINAL'" class="radio-wrapper">
                <s25-ng-radio
                    [(modelValue)]="data.totalType"
                    [value]="'amount'"
                    [name]="'paymentType'"
                    (modelValueChange)="updatePaymentType(data.prop)"
                    >Amount</s25-ng-radio
                >
                <s25-ng-radio
                    [(modelValue)]="data.totalType"
                    [value]="'percent'"
                    [name]="'paymentType'"
                    (modelValueChange)="updatePaymentType(data.prop)"
                    >Percentage</s25-ng-radio
                >
            </div>
            <div *ngIf="data.type !== 'FINAL'" class="amount-container">
                <s25-ng-editable-number
                    [type]="'float'"
                    [max]="data.totalType === 'percent' ? 100 : this.requestAmt"
                    [alwaysEditing]="true"
                    [val]="data.totalType === 'percent' ? 100 : this.requestAmt"
                    (valChange)="processRequestAmt($event, data.prop)"
                    (disablingError)="onError($event)"
                ></s25-ng-editable-number>
            </div>
        </ng-template>

        <!-- Main Content -->
        <div *ngIf="init" [ngSwitch]="currentView">
            <button *ngSwitchCase="null" class="aw-button aw-button--primary" (click)="managePayments()">
                Manage Payments
            </button>
            <div *ngIf="currentView" class="payment-tabs c-margin-bottom--single">
                <button
                    *ngIf="stripeEnabled || sevenPointEnabled"
                    class="c-textButton"
                    [class.active]="currentView === 'request'"
                    (click)="toggleView('request')"
                >
                    Send Request
                </button>
                <button class="c-textButton" [class.active]="currentView === 'history'" (click)="toggleView('history')">
                    History
                </button>
                <button class="btn btn-flat btn-icon" (click)="reset()">
                    <svg *ngIf="currentView === 'history'" class="c-svgIcon">
                        <title>Refresh History</title>
                        <use
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#refresh"
                        ></use>
                    </svg>
                </button>
            </div>
            <div *ngSwitchCase="'history'" class="history-wrapper">
                <fieldset *ngIf="!viewOnly" class="amount-form">
                    <ng-container *ngTemplateOutlet="sideBarForm; context: { data: manualPaymentData }"></ng-container>
                    <button class="aw-button aw-button--outline" (click)="addManualPayment()">Add Payment</button>
                </fieldset>
                <div *ngIf="historyRows.length === 0" class="noResults">No Payment History Found</div>
                <table *ngIf="historyRows.length > 0" class="table table-bordered ngListTbl ngTable b-listview">
                    <thead class="ngTableHeader">
                        <tr class="ngTableRow">
                            <th class="b-listview-th ngTableCell">Id</th>
                            <th class="b-listview-th ngTableCell">Create Date</th>
                            <th class="b-listview-th ngTableCell">Due Date</th>
                            <th class="b-listview-th ngTableCell">Source</th>
                            <th class="b-listview-th ngTableCell">Description</th>
                            <th class="b-listview-th ngTableCell">Amount</th>
                            <th class="b-listview-th ngTableCell">Type</th>
                            <th class="b-listview-th ngTableCell">Status</th>
                            <th class="b-listview-th ngTableCell">Void</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let row of historyRows"
                            class="b-listview-tr ngListRow ngTableRow"
                            [attr.aria-label]="row.isVoidRow ? 'Void Payment Form' : null"
                            [attr.role]="row.isVoidRow ? 'listitem' : null"
                        >
                            <ng-container *ngIf="row.isVoidRow">
                                <td colspan="9" style="padding: unset">
                                    <s25-ng-void-payment
                                        [voidRow]="row"
                                        (cancelVoid)="onCancelVoid($event)"
                                        (voidPayment)="onVoid($event)"
                                    ></s25-ng-void-payment>
                                </td>
                            </ng-container>

                            <ng-container *ngIf="!row.isVoidRow">
                                <td class="ngTableCell">{{ row.paymentDetailId }}</td>
                                <td class="ngTableCell">{{ row.date }}</td>
                                <td class="ngTableCell">{{ row.dueDate }}</td>
                                <td class="ngTableCell">{{ row.source }}</td>
                                <td class="ngTableCell">{{ row.productDescription }}</td>
                                <td class="ngTableCell">{{ row.amount }}</td>
                                <td class="ngTableCell">{{ row.type }}</td>
                                <td class="ngTableCell">
                                    <ng-container
                                        *ngIf="row.source !== 'manual' || row.paymentStatus === 'paid' || row.isVoid"
                                    >
                                        <span>{{ row.paymentStatus }}</span
                                        ><span *ngIf="!row.isVoid && row.paymentStatus === 'paid'"
                                            >{{ row.customerId ? " by " + row.customerId : ""
                                            }}{{ row.paymentSuccess ? " on " + row.paymentSuccess : "" }}</span
                                        >
                                    </ng-container>
                                    <ng-container
                                        *ngIf="row.source === 'manual' && row.paymentStatus !== 'paid' && !row.isVoid"
                                    >
                                        <button
                                            *ngIf="!viewOnly"
                                            class="aw-button aw-button--outline"
                                            (click)="setPaid(row)"
                                            [disabled]="row.disabled"
                                        >
                                            Mark as Paid
                                        </button>
                                        <span *ngIf="viewOnly">{{ row.paymentStatus }}</span>
                                    </ng-container>
                                </td>
                                <td *ngIf="!row.isVoid" class="ngTableCell">
                                    <button
                                        *ngIf="!viewOnly && (row.source === 'manual' || row.source === 'stripe')"
                                        class="aw-button aw-button--outline void-button"
                                        (click)="showVoidForm(row)"
                                        [disabled]="row.disabled"
                                    >
                                        Void
                                    </button>
                                    <span *ngIf="viewOnly || (row.source !== 'manual' && row.source !== 'stripe')"
                                        >Cannot Void</span
                                    >
                                </td>
                                <td *ngIf="row.isVoid" class="ngTableCell">
                                    Yes{{ row.voidUsername ? " by " + row.voidUsername : ""
                                    }}{{ row.voidDate ? " on " + row.voidDate : ""
                                    }}{{ row.voidReason ? " because: " + row.voidReason : "" }}
                                </td>
                            </ng-container>
                        </tr>
                    </tbody>
                </table>
                <div *ngIf="typeError" class="ngRed ngBold">{{ typeError }}</div>
                <button
                    *ngIf="!stripeEnabled && !sevenPointEnabled"
                    class="aw-button aw-button--outline"
                    (click)="toggleView(null)"
                >
                    Cancel
                </button>
            </div>
            <ng-container *ngIf="stripeEnabled || sevenPointEnabled">
                <div *ngSwitchCase="'request'" class="request-wrapper">
                    <fieldset class="amount-form c-margin-bottom--half">
                        <ng-container
                            *ngTemplateOutlet="sideBarForm; context: { data: requestPaymentData }"
                        ></ng-container>
                    </fieldset>
                    <fieldset *ngIf="requestAmtEntered" class="email-form">
                        <div class="email-inputs">
                            <div class="to-email">
                                <s25-ng-multiselect-search-criteria
                                    [modelBean]="{ textButton: true, title: 'TO' }"
                                    [type]="'contacts'"
                                    [selectedItems]="selectedContacts"
                                    (changed)="updateEmails()"
                                ></s25-ng-multiselect-search-criteria>
                                <input
                                    type="text"
                                    class="c-input"
                                    [(ngModel)]="emails"
                                    aria-label="Enter email addresses for payment request recipients"
                                />
                            </div>
                            <label>
                                From
                                <input class="c-input" [(ngModel)]="fromAddress" />
                            </label>
                            <label>
                                Subject
                                <input class="c-input" [(ngModel)]="emailSubject" />
                            </label>
                        </div>
                        <label class="email-body">
                            Message Body
                            <p class="ngFinePrint c-margin-top--quarter">
                                Link is added to the email body when request is sent
                            </p>
                            <s25-ng-rich-text-editor
                                [(modelValue)]="emailBody"
                                [autoResize]="true"
                            ></s25-ng-rich-text-editor>
                        </label>
                        <div *ngIf="typeError" class="ngRed ngBold">{{ typeError }}</div>
                        <div class="button-group c-margin-top--half">
                            <button class="aw-button aw-button--primary" (click)="sendEmail()">Send Request</button>
                            <button class="aw-button aw-button--outline" (click)="toggleView(null)">Cancel</button>
                        </div>
                    </fieldset>
                </div>
            </ng-container>
            <div class="terminal-message" [class.fade-message]="terminalMessage">
                <s25-ng-icon *ngIf="terminalMessage" [type]="'check'"></s25-ng-icon>
                {{ terminalMessage }}
            </div>
            <div *ngIf="alreadyPaid">Paid In Full</div>
        </div>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25PaymentsComponent implements OnInit {
    @Input() amountInCents: number;
    @Input() currency: string = "usd";
    @Input() productName: string;
    @Input() productDescription: string;
    @Input() evBillId: number;
    @Input() eventId: number;
    @Input() orgId: number;

    @Output() onCreatePayment: EventEmitter<number> = new EventEmitter<number>();

    @ViewChild(S25EditableNumberComponent) editableNumComp: S25EditableNumberComponent;

    sources: { id: IntegrationTypes; name: string }[] = [];
    init: boolean;
    alreadyPaid: boolean;
    emails: string;
    terminalMessage: string;
    showEmailForm: boolean = false;
    selectedContacts: S25ItemI[];
    orgName: string;
    eventName: string;
    emailSubject: string;
    emailBody: string;
    fromAddress: string;
    requestAmt: number;
    error: boolean;
    typeError: string;
    requestAmtEntered: boolean = true;
    currentView: "request" | "history" | null = null;
    datePref: string;
    historyRows: PaymentHistoryRow[];
    remainingBalance: number;
    totalPayments: number;
    stripeEnabled: boolean;
    sevenPointEnabled: boolean;
    viewOnly: boolean;

    manualPaymentData: PaymentFormData = {
        amount: 0,
        dueDate: { date: new Date() },
        type: "DEPOSIT",
        description: "",
        replacementId: 0,
        paymentStatus: "paid",
        totalType: "amount",
        prop: "manual",
    };
    requestPaymentData: PaymentFormData = {
        amount: 0,
        dueDate: { date: new Date() },
        type: "DEPOSIT",
        source: null,
        totalType: "amount",
        prop: "request",
    };

    constructor(
        private cd: ChangeDetectorRef,
        private elementRef: ElementRef,
        private balanceUpdateService: BalanceUpdateService,
    ) {}

    private setDefaultEmailBody() {
        this.emailBody =
            `<p>` +
            `An invoice has been generated in the amount of ` +
            `<b>${PricingService.formatCurrency(this.requestAmt)}</b> ` +
            `for ` +
            `<b>${this.orgName}</b> ` +
            `regarding billing for ` +
            `<b>${this.eventName}</b>. ` +
            `Click {{paymentLink("here")}} to make a payment.` +
            `</p>`;
        if (this.requestPaymentData.source?.id === IntegrationTypes.sevenPoint) {
            this.emailBody +=
                `<p>` +
                `Use the following unique payment identifier on the 7 Point Solutions payment portal: ` +
                `"{{uniquePaymentIdentifier}}".` +
                `</p>`;
        }
    }

    async ngOnInit() {
        S25LoadingApi.init(this.elementRef.nativeElement);

        return S25Util.all({
            stripeEnabled: IntegrationService.getIntegrationEnabled(IntegrationTypes.stripe),
            sevenPointEnabled: IntegrationService.getIntegrationEnabled(IntegrationTypes.sevenPoint),
            paymentDetails: PaymentService.getFormattedPayments(this.evBillId, this.orgId, this.amountInCents / 100),
            orgData: OrganizationService.getOrganizationById(this.orgId),
            eventName: EventService.getEventName(this.eventId),
            datePref: UserprefService.getS25Dateformat(),
            fls: FlsService.getFls(),
        }).then((resp) => {
            this.viewOnly = resp.fls?.MANAGE_PAY !== "F";
            this.totalPayments = resp?.paymentDetails?.totalPayments;
            this.remainingBalance = resp?.paymentDetails?.remainingBalance;
            this.requestAmt = this.remainingBalance;

            this.stripeEnabled = !this.viewOnly && resp?.stripeEnabled;
            if (this.stripeEnabled && S25Util.array.findByProp(this.sources, "id", IntegrationTypes.stripe) === -1) {
                this.sources.push({
                    id: IntegrationTypes.stripe,
                    name: S25Util.firstCharToUpper(IntegrationTypes.stripe),
                });
            }

            this.sevenPointEnabled = !this.viewOnly && resp?.sevenPointEnabled;
            if (
                this.sevenPointEnabled &&
                S25Util.array.findByProp(this.sources, "id", IntegrationTypes.sevenPoint) === -1
            ) {
                this.sources.push({
                    id: IntegrationTypes.sevenPoint,
                    name: "7 Point Solutions",
                });
            }

            // we don't show the sources dropdown for only 1 source, so just set it to the one and only available
            if (!this.requestPaymentData.source && this.sources.length === 1) {
                this.requestPaymentData.source = this.sources[0];
            }

            this.alreadyPaid =
                resp?.paymentDetails?.evBillId === this.evBillId && this.amountInCents === this.totalPayments;

            this.orgName = resp?.orgData?.organization_name;
            this.eventName = resp?.eventName;
            this.datePref = resp?.datePref;
            this.historyRows =
                resp?.paymentDetails?.data?.map((row: IPaymentDetails) => {
                    return S25Util.extend({}, row, {
                        type: row.type === "FINAL" ? "BALANCE" : row.type, // FINAL renamed to BALANCE for display purposes
                        date: S25Datefilter.transform(row.paymentCreated, this.datePref),
                        voidDate: S25Datefilter.transform(row.voidDate, this.datePref),
                        paymentSuccess: S25Datefilter.transform(row.paymentSuccess, this.datePref),
                        dueDate: S25Datefilter.transform(row.dueDate, this.datePref),
                        amount: PricingService.formatCurrency((row.amountTotalCents ?? 0) / 100),
                    });
                }) ?? [];

            this.emailSubject = `Payment Request for ${this.eventName}`;
            this.setDefaultEmailBody();
            this.fromAddress = "no_reply@collegenet.com";

            const billingContacts: Contact.DataI[] = resp?.orgData?.contact?.filter(
                (contact: Contact.DataI) => contact.contact_role_id === -1,
            );

            this.updateEmails(billingContacts);

            this.selectedContacts = billingContacts?.map((contact) => {
                return {
                    itemId: contact.contact_id,
                    itemName: contact.contact_name,
                    itemDesc: contact.contact_email,
                };
            });

            this.init = true;
            S25LoadingApi.destroy(this.elementRef.nativeElement);
            this.cd.detectChanges();
        });
    }

    async sendEmail() {
        if (!this.requestPaymentData.source) {
            S25Util.showError("A payment processor must be selected");
            return;
        }

        this.terminalMessage = "";
        this.typeError = "";

        const paymentInCents =
            this.requestPaymentData.type !== "FINAL" ? this.requestAmt * 100 : this.amountInCents - this.totalPayments;

        try {
            let cancelUrl = "";
            let successUrl = "";

            if (this.requestPaymentData.source.id === IntegrationTypes.stripe) {
                cancelUrl = window.location.origin + window.location.pathname + "#!/home/payment/cancel";
                successUrl =
                    window.location.origin + window.location.pathname + "#!/home/payment/success/{CHECKOUT_SESSION_ID}";
            }

            const payload: ICheckoutRequest = {
                currency: this.currency,
                amountInCents: paymentInCents,
                productName: this.productName,
                productDescription: this.productDescription,
                cancelUrl: cancelUrl,
                successUrl: successUrl,
                evBillId: this.evBillId,
                organizationId: this.orgId,
                eventId: this.eventId,
                type: this.requestPaymentData.type,
                source: this.requestPaymentData.source.id,
                replacementId: 0,
                dueDate:
                    this.requestPaymentData.dueDate?.date &&
                    S25Util.date.toS25ISODateStrStartOfDay(this.requestPaymentData.dueDate.date),
                paymentStatus: "unpaid",
            };

            TelemetryService.sendWithSub(
                "Pricing",
                "Event",
                "PaymentAdd" + S25Util.firstCharToUpper(this.requestPaymentData.source.id),
            );
            let paymentDetails = await PaymentService.createPayment(payload);
            if (paymentDetails?.paymentDetailId) {
                let paymentUrlPromise: Promise<string>;
                if (this.requestPaymentData.source.id === IntegrationTypes.stripe) {
                    paymentUrlPromise = jSith.when(
                        window.location.origin +
                            window.location.pathname +
                            "#!/payment/portal/" +
                            paymentDetails.paymentDetailId,
                    );
                } else if (this.requestPaymentData.source.id === IntegrationTypes.sevenPoint) {
                    paymentUrlPromise = IntegrationService.getIntegration(IntegrationTypes.sevenPoint).then(
                        (integration) => {
                            return integration.url;
                        },
                    );
                }

                let paymentUrl = await paymentUrlPromise;
                let tos: string[] = [];
                if (this.emails) {
                    tos = this.emails
                        .split(/[,;]/)
                        .map((email) => email.trim())
                        .filter((email) => !!email);
                }

                let finalEmailBody = this.emailBody
                    .replace(/{{paymentLink\("(.*?)"\)}}/, `<a href="${paymentUrl}">$1</a>`)
                    .replace("{{uniquePaymentIdentifier}}", `${S25Const.instanceId}/${paymentDetails.paymentDetailId}`);
                await EmailService.sendEmail(this.emailSubject, finalEmailBody, tos, this.fromAddress);

                this.terminalMessage = "Emails Sent Successfully";
                this.showEmailForm = false;
                this.cd.detectChanges();
            } else {
                S25Util.showError("Error creating payment details");
            }
        } catch (e) {
            if (e.error?.message === "already_exists") {
                this.displayPaymentTypeError(this.requestPaymentData.type);
            } else {
                S25Util.showError(e);
            }
        }
    }

    managePayments() {
        if (this.stripeEnabled || this.sevenPointEnabled) {
            this.toggleView("request");
        } else {
            this.toggleView("history");
        }
    }

    toggleView(view: "request" | "history") {
        TelemetryService.sendWithSub("Pricing", "Event", "Payment" + S25Util.firstCharToUpper(view));

        const propData = this.setFormDataByProp(view === "history" ? "manual" : "request");
        this.requestAmt = this.remainingBalance;
        this.processRequestAmt(propData.totalType === "amount" ? this.requestAmt : 100, propData.prop);
        view === "request" && this.setDefaultEmailBody();
        this.typeError = null;
        this.currentView = view;
        this.cd.detectChanges();
    }

    updateEmails(contacts?: S25ItemI[] | Contact.DataI[]) {
        contacts ??= this.selectedContacts;

        this.emails = contacts
            ?.map((contact: S25ItemI) => contact.itemDesc || contact.contact_email)
            .filter((email) => !!email)
            .join("; ");

        this.cd.detectChanges();
    }

    @Debounce(500)
    processRequestAmt(input: number, dataProp: PaymentFormData["prop"]) {
        const prop = this.setFormDataByProp(dataProp);

        this.requestAmtEntered = false;
        this.cd.detectChanges();

        if (this.error) return;

        if (prop.type !== "FINAL") {
            input ??= this.requestAmt;
            this.requestAmt = prop.totalType === "percent" ? this.remainingBalance * (input / 100) : input;
        } else {
            this.requestAmt = this.remainingBalance;
        }

        this.currentView === "request" && this.setDefaultEmailBody();

        this.requestAmtEntered = true;
        this.error = false;

        this.cd.detectChanges();
    }

    updatePaymentType(prop: PaymentFormData["prop"]) {
        const propData = this.setFormDataByProp(prop);
        this.error = false;

        this.editableNumComp.val = propData.totalType === "amount" ? +(this.amountInCents / 100).toFixed(2) : 100;
        this.editableNumComp.errorMessages = [];
        this.editableNumComp.ngOnInit();

        this.processRequestAmt(this.editableNumComp.val, prop);
    }

    onSelectedSourceChange = ($event: any) => {
        this.requestPaymentData.source = $event;
        this.setDefaultEmailBody();
        this.cd.detectChanges();
    };

    onError(error: boolean) {
        this.error = error;
        this.requestAmtEntered = !error;
        this.cd.detectChanges();
    }

    @Telemetry({ category: "Pricing", subCategory: "Event", type: "PaymentAddManual" })
    async addManualPayment() {
        this.typeError = null;
        const { dueDate, type, description, replacementId, paymentStatus } = this.manualPaymentData;
        if (this.requestAmt !== 0) {
            let payload: ICheckoutRequest = {
                productDescription: description,
                amountInCents: this.requestAmt * 100,
                evBillId: this.evBillId,
                organizationId: this.orgId,
                eventId: this.eventId,
                type: type,
                currency: "usd",
                source: "manual",
                successUrl: "",
                cancelUrl: "",
                replacementId: replacementId,
                dueDate: dueDate?.date && S25Util.date.toS25ISODateStrStartOfDay(dueDate.date),
                paymentStatus: paymentStatus,
            };
            return PaymentService.createPayment(payload).then(
                () => {
                    this.balanceUpdateService.updateBalance({ amount: this.requestAmt, orgId: this.orgId });
                    this.onCreatePayment.emit(this.evBillId);
                    return this.reset();
                },
                (error) => {
                    if (error?.data?.message === "already_exists") {
                        this.displayPaymentTypeError(type);
                        return;
                    } else {
                        S25Util.showError(error);
                    }
                },
            );
        }
    }

    setPaid = async (row: PaymentHistoryRow) => {
        await PaymentService.setPaid(row.paymentDetailId);
        await this.reset();
    };

    async showVoidForm(row: PaymentHistoryRow) {
        row.disabled = true;
        let voidRow: PaymentHistoryRow = {
            isVoidRow: true,
            rowToVoid: row,
            amountTotalCents: 0,
            amount: "",
            date: "",
            paymentDetailId: 0,
            disabled: false,
            isVoid: false,
            productDescription: "",
            productName: "",
            source: "",
            status: "",
            paymentStatus: "",
            type: undefined,
        };
        this.historyRows.splice(this.historyRows.indexOf(row) + 1, 0, voidRow);
        this.cd.detectChanges();
    }

    async onVoid(voidRow: PaymentHistoryRow) {
        this.balanceUpdateService.updateBalance({
            amount: -(voidRow.rowToVoid.amountTotalCents / 100),
            orgId: this.orgId,
        });
        await this.reset();
    }

    onCancelVoid(voidRow: PaymentHistoryRow) {
        voidRow.rowToVoid.disabled = false;
        this.historyRows.splice(this.historyRows.indexOf(voidRow), 1); // remove void row
        this.cd.detectChanges();
    }

    displayPaymentTypeError(type: IPaymentDetails["type"]) {
        this.typeError = `A ${type} payment already exists. Only one payment of this type is allowed.`;
        this.cd.detectChanges();
    }

    reset() {
        this.init = false;
        this.cd.detectChanges();
        return this.ngOnInit();
    }

    setFormDataByProp(prop: PaymentFormData["prop"]) {
        return this[`${prop}PaymentData`];
    }

    protected readonly parseFloat = parseFloat;
}

export interface PaymentHistoryRow extends IPaymentDetails {
    date: string;
    amount: string;
    disabled: boolean;
    isVoidRow: boolean;
    rowToVoid?: PaymentHistoryRow;
}

export interface PaymentFormData {
    amount: number;
    description?: string;
    dueDate: { date: Date };
    paymentStatus?: "paid" | "unpaid";
    prop: "manual" | "request";
    replacementId?: number;
    source?: { id: IntegrationTypes; name: string };
    totalType: "amount" | "percent";
    type: IPaymentDetails["type"];
}
