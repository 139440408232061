import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
    ViewRef,
} from "@angular/core";
import { EventService } from "../../services/event.service";
import { jSith } from "../../util/jquery-replacement";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { PricingService } from "../../services/pricing.service";
import { RateService } from "../../services/rate.service";
import { TaxesService } from "../../services/taxes.service";
import { LangService } from "../../services/lang.service";
import { UserprefService } from "../../services/userpref.service";
import { FreshbooksService } from "../../services/freshbooks.service";
import { FlsService } from "../../services/fls.service";
import { OlsService } from "../../services/ols.service";
import { S25LoadingApi } from "../s25-loading/loading.api";
import { S25PricingOrgsComponent } from "./pricing-org-table-components/s25-pricing-orgs/s25.pricing.orgs.component";
import { S25PricingTotalsComponent } from "./pricing-org-table-components/s25-pricing-totals/s25.pricing.totals.component";
import {
    AccountOccurrenceSubtotal,
    AccountProfileSubTotal,
    Billing,
    PricingModel,
    PricingOrg,
    PricingOrgData,
    TotalsModel,
} from "../../pojo/Pricing";
import { EventI } from "../../pojo/EventI";
import { Bind } from "../../decorators/bind.decorator";
import { S25ModalComponent } from "../s25-modal/s25.modal.component";
import { Event } from "../../pojo/Event";
import Occurrence = Event.Occurrence;
import { OptBean } from "../s25-opt/s25.opt.component";
import { StateService } from "../../services/state.service";

@TypeManagerDecorator("s25-ng-pricing")
@Component({
    selector: "s25-ng-pricing",
    template: `<div *ngIf="init" class="pricing_wrapper">
        <s25-loading-inline [model]="{}"></s25-loading-inline>
        <div *ngIf="pricingInit">
            <s25-ng-opt [modelBean]="optBean"></s25-ng-opt>
            <div class="pricing_header">
                <span>{{ lang.summary }}</span>
            </div>
            <div class="pricing_date">
                <span class="ngBold">{{ lang.pricing_date }}</span>
                <span *ngIf="!canEditPricing" class="c-margin-left--half">{{
                    datePickerBean.date | dateFormat: datePickerBean.dateFormat
                }}</span>
                <div *ngIf="canEditPricing" class="date-picker-wrapper c-margin-left--half">
                    <s25-datepicker
                        *ngIf="canEditPricing"
                        [(modelValue)]="datePickerBean"
                        (modelValueChange)="updateDate()"
                    ></s25-datepicker>
                </div>
                <div class="c-margin-top--half">
                    <s25-ng-pricing-org-summary
                        [modelBean]="orgSummaryBean"
                        (signalScroll)="scrollToOrg($event)"
                    ></s25-ng-pricing-org-summary>
                </div>
            </div>
            <div>
                <div *ngIf="eventsNeedingRefresh?.length > 0 && canEditPricing" class="ngPadBottom10">
                    <div class="ngBold ngRed">
                        The following events have been recently edited and may require a pricing refresh:
                    </div>
                    <div *ngFor="let event of eventsNeedingRefresh">
                        <button
                            *ngIf="!event.hideRefresh"
                            (click)="refreshPricing(event)"
                            class="aw-button aw-button--primary"
                        >
                            Refresh {{ event.event_name }}
                        </button>
                        <span *ngIf="event.loading">{{ event.loading }}</span>
                        <span *ngIf="event.loadingSuccess">{{ event.loadingSuccess }}</span>
                    </div>
                </div>
                <div *ngIf="eventsNeedingRefresh.length === 0 && canEditPricing" class="ngPadBottom10">
                    <button (click)="refreshPricing(thisEvent)" class="aw-button aw-button--outline">
                        Refresh this Event's Pricing
                    </button>
                    <span *ngIf="thisEvent.loading" class="c-margin-left--single">{{ thisEvent.loading }}</span>
                </div>
                <div *ngIf="!optBean.combineRelatedEvents && canEditPricing" class="ngPadBottom10 update_rate_groups">
                    <label for="allRateGroups"
                        >Update All Items to Rate Group:
                        <div class="rate_group">
                            <s25-ng-pricing-rate-group
                                [modelBean]="eventRateGroupModel"
                                (updateRateGroup)="updateAllRateGroups($event)"
                            ></s25-ng-pricing-rate-group>
                        </div>
                    </label>

                    <button
                        *ngIf="modelBean.chosenPricingSet.id > 0 && canEditPricing && !setDeleteDisabled"
                        class="aw-button aw-button--danger--outline c-margin-left--half"
                        [disabled]="showSetDeleteWarning"
                        (click)="modelBean.deletePricingSet()"
                    >
                        Delete Invoice: {{ modelBean.chosenPricingSet.billDefn.billName }}
                    </button>
                    <button
                        *ngIf="canEditPricing"
                        class="aw-button aw-button--outline c-margin-left--half"
                        [disabled]="showSetDeleteWarning"
                        (click)="modelBean.openPricingSetModal()"
                    >
                        Create Invoice
                    </button>
                </div>

                <div *ngIf="modelBean.canCreatePricingSet" class="c-margin-bottom--single">
                    <div class="pricing_select navbar">
                        <ng-container *ngFor="let set of modelBean.pricingSets">
                            <button
                                class="c-textButton"
                                [class.active]="set.id === modelBean.chosenPricingSet.id"
                                (click)="togglePricingSet(set)"
                            >
                                {{ set.billDefn.billName }}
                            </button>
                        </ng-container>
                    </div>
                </div>

                <div *ngIf="showSetDeleteWarning" class="warning-message">
                    At least one invoice is associated with this pricing set. Please confirm you'd like to delete it.
                    <div class="button-container">
                        <button class="aw-button aw-button--outline" (click)="this.modelBean.cancelDelete()">
                            Cancel
                        </button>
                        <button
                            class="aw-button aw-button--danger--outline"
                            (click)="this.modelBean.deletePricingSet()"
                        >
                            Confirm
                        </button>
                    </div>
                </div>

                <s25-ng-modal #pricingSetModal [title]="'Create Invoice'" [size]="'xl'">
                    <ng-template #s25ModalBody>
                        <s25-ng-pricing-set
                            [modelBean]="modelBean"
                            [optBean]="optBean"
                            (close)="refresh()"
                        ></s25-ng-pricing-set>
                    </ng-template>
                </s25-ng-modal>

                <s25-ng-pricing-orgs
                    [orgLists]="orgLists"
                    [optBean]="optBean"
                    (refreshPricing)="refresh($event)"
                    (refreshOpt)="optRefresh()"
                    (manualPaymentCreated)="disableDeleteSet($event)"
                ></s25-ng-pricing-orgs>

                <div class="pricing_header">{{ lang.totals }}</div>
                <s25-ng-pricing-totals [modelBean]="pricingTotalsModel"></s25-ng-pricing-totals>
            </div>
        </div>
    </div>`,
    styles: `
        .pricing_wrapper {
            position: relative;
            top: -1em;

            s25-loading-inline {
                display: block;
                width: fit-content;
                margin: 2em auto 0;
            }

            .date-picker-wrapper {
                display: inline-block;
                width: fit-content;
            }

            .update_rate_groups {
                display: flex;
            }

            .pricing_select {
                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;

                &.navbar {
                    justify-content: unset;
                    margin-top: unset;
                }

                .c-textButton {
                    color: rgba(0, 0, 0, 0.8) !important;
                    min-height: 2.5em;
                    outline-offset: 4px;
                    padding: 0.5rem 1.5rem;

                    &.active {
                        color: #2573a7 !important;
                        border-bottom: 3px solid #2573a7;
                    }
                }
            }

            .warning-message {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 98%;
                gap: 10px;
                padding: 1em;
                border: 1px solid #d62000;
                border-left: 3px solid #d62000;
                border-radius: 5px;
                color: #d62000;

                .button-container {
                    display: flex;
                    gap: 1em;

                    .aw-button--outline {
                        background-color: #fff !important;

                        &:hover {
                            background-color: #3273a0 !important;
                            color: #fff !important;
                        }
                    }
                }
            }

            s25-ng-pricing-set {
                flex-basis: 100%;
            }
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25PricingComponent implements OnInit {
    @Input() itemId: any;

    @ViewChild(S25PricingOrgsComponent) orgsLists: S25PricingOrgsComponent;
    @ViewChild(S25PricingTotalsComponent) totalsTable: S25PricingTotalsComponent;
    @ViewChild("pricingSetModal") pricingSetModal: S25ModalComponent;

    init = false;
    pricingInit = false;
    modelBean: PricingModel;
    optBean: OptBean = {} as OptBean;
    evBillId: number;
    orgLists: PricingOrg[];
    commonListBean: any;
    thisEvent: { event_id: number; loading?: string };
    eventIds: number[];
    eventsNeedingRefresh: { hideRefresh: boolean; event_name: string; loading: boolean; loadingSuccess: boolean }[] =
        [];
    dateTimeFormat: string;
    lang: any;
    canEditPricing: boolean;
    datePickerBean: any;
    orgSummaryBean: PricingModel;
    eventRateGroupModel: PricingOrgData;
    pricingTotalsList: any;
    pricingTotalsModel: TotalsModel;
    eventPricingData: EventI;
    invoiceData: any;
    showSetDeleteWarning: boolean;
    setDeleteDisabled: boolean;
    selectedView: OptBean["selectedView"];
    idToBillName: { [key: string]: Set<string> } = { eventType: new Set() };

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    async ngOnInit() {
        if (!this.evBillId) {
            const urlArr = window.location.hash.split("/");
            const evBillId = parseInt(urlArr[urlArr.length - 1]);
            const location = urlArr[urlArr.length - 2];

            if (S25Util.isDefined(evBillId) && location === "pricing") {
                this.evBillId = evBillId;
            } else {
                this.evBillId = null;
            }
        }

        this.init = true;
        this.cd.detectChanges();

        S25LoadingApi.init(this.elementRef.nativeElement);

        this.modelBean = {
            defaultPricingSet: {
                id: -999,
                billDefn: {
                    billName: "Standard Event Pricing",
                },
            },
            canCreatePricingSet: false,
            openPricingSetModal: () => {
                this.modelBean.pricingSet = {
                    itemId: this.itemId,
                };
                this.pricingSetModal.open();
            },
            getPricingSets: async () => {
                try {
                    let eventIds =
                        (this.optBean &&
                            this.optBean.combineRelatedEvents &&
                            (await EventService.getRelatedEventIds(this.itemId))) ||
                        (await jSith.when([]));

                    eventIds.push(this.itemId);
                    eventIds = S25Util.array.unique(eventIds);

                    let pricingSets = await PricingService.getPricingSetsForEvents(eventIds);
                    pricingSets = S25Util.array.uniqueByProp(pricingSets, "id");

                    pricingSets.reduce((map: { [key: string]: Set<string> }, set: Billing) => {
                        if (set.id === this.evBillId) this.setDeleteDisabled = set.payments?.length > 0;

                        set.billDefn.reservations.forEach((res) => {
                            if (!map[res.rsrv_id]) map[res.rsrv_id] = new Set();
                            map[res.rsrv_id].add(set.billDefn.billName);
                        });

                        set.billDefn.requirements.forEach((req) => {
                            if (!map[req.requirementId]) map[req.requirementId] = new Set();
                            map[req.requirementId].add(set.billDefn.billName);
                        });

                        if (set.billDefn.includeEventType) map.eventType.add(set.billDefn.billName);

                        return map;
                    }, this.idToBillName);

                    this.modelBean.pricingSets = [this.modelBean.defaultPricingSet, ...pricingSets];
                } catch (error) {
                    S25Util.showError(error);
                }
            },
            selectPricingSet: async () => {
                this.evBillId =
                    this.modelBean.chosenPricingSet.id === this.modelBean.defaultPricingSet.id
                        ? null
                        : this.modelBean.chosenPricingSet.id;

                S25LoadingApi.init(this.elementRef.nativeElement);
                this.pricingInit = false;
                this.orgLists = [];
                this.cd.detectChanges();
                await this.initPricing();
                this.pricingInit = true;
                this.cd.detectChanges();
                S25LoadingApi.destroy(this.elementRef.nativeElement);

                StateService.go(
                    ".",
                    {
                        evBillId: this.evBillId,
                    },
                    {},
                );
            },
            deletePricingSet: async () => {
                if (this.setDeleteDisabled) return;

                if (this.invoiceData?.customBillInvoices?.length > 0 && !this.showSetDeleteWarning) {
                    this.showSetDeleteWarning = true;
                    this.cd.detectChanges();
                    return;
                }
                try {
                    await PricingService.deletePricingSet(this.modelBean.chosenPricingSet.id);
                    this.idToBillName = { eventType: new Set() };
                    await this.modelBean.getPricingSets();
                    this.modelBean.chosenPricingSet = S25Util.deepCopy(this.modelBean.defaultPricingSet);
                    this.modelBean.selectPricingSet();
                    this.showSetDeleteWarning = false;
                } catch (error) {
                    S25Util.showError(error);
                }
            },
            cancelDelete: () => {
                this.showSetDeleteWarning = false;
                this.cd.detectChanges();
            },
        };

        this.itemId = parseInt(this.itemId);
        this.orgLists = this.orgLists || [];
        this.commonListBean = {
            comptype: "pricing",
            eventId: this.itemId,
            caption: "Pricing List",
        };

        this.optBean = Object.assign(this.optBean, {
            itemId: this.itemId,
            itemTypeId: 1,
            compsubject: "event",
            comptype: "pricing",
            helpTopic: "view_eventsingle_pricing",
            searchContext: "&pricing_event_id=" + this.itemId, //just used to make call unique to subject context if cached
            hasOpt: true,
            hasMoreActions: true,
            hasClose: true,
            hasRefresh: true,
            hasDatepicker: false,
            hasHideNoChargeItems: true,
            autoInit: false,
            hasBBStyle: false,
            refreshF: this.optRefresh,
            hasHelp: true,
            hasColumnChooser: true,
            colChooseBean: this.getColChooseBean(),
        });

        await this.initPricing();
        this.pricingInit = true;
        S25LoadingApi.destroy(this.elementRef.nativeElement);
        this.cd.detectChanges();
    }

    scrollToOrg(orgData: string) {
        this.orgsLists.scrollToOrg(orgData);
    }

    async refreshPricing(event: any) {
        event.hideRefresh = true;
        event.loading = "Refresh Processing...";
        event.loadingSuccess = "";

        this.cd.detectChanges();

        try {
            await EventService.updateEventPricing(event.event_id);

            event.loading = "";
            event.loadingSuccess = "Update complete, please refresh this page to see updated pricing";
            await this.refresh();
        } catch (error) {
            event.hideRefresh = false;
            event.loading = "";
            event.loadingSuccess = "";
            S25Util.showError(error);
        }
    }

    async getEventsNeedingRefresh() {
        if (
            S25Util.array.shallowIntersection(this.eventIds, EventService.getEventIdsNeedingRefreshLocal(), null).length
        ) {
            this.eventsNeedingRefresh = await EventService.getEventsNeedingRefresh(this.eventIds);
        }
    }

    async initPricing() {
        this.thisEvent = { event_id: parseInt(this.itemId) };
        const [
            eventPricingData,
            rateGroups,
            taxesMap,
            lang,
            dateFormat,
            dateTimeFormat,
            timeFormat,
            hasFreshbooks,
            invoiceData,
            fls,
            ols,
            isScheduler,
        ] = await Promise.all([
            this.evBillId
                ? PricingService.getPricingFromPricingSetId(this.itemId, this.evBillId)
                : PricingService.getPricing(this.itemId, this.optBean?.combineRelatedEvents),
            RateService.getRateGroups(),
            TaxesService.getTaxesMap(),
            LangService.getLang(),
            UserprefService.getS25Dateformat(),
            UserprefService.getS25DateTimeformat(),
            UserprefService.getS25Timeformat(),
            FreshbooksService.hasFreshbooksApiToken(),
            FreshbooksService.getS25Invoices(this.itemId, this.evBillId),
            FlsService.getFls(),
            OlsService.getOls([this.itemId], 1, "edit"),
            EventService.isCurrentScheduler(this.itemId),
        ]);

        this.invoiceData = invoiceData?.invoice;

        let editAccess = ols[0]?.access_level;
        this.modelBean.canCreatePricingSet =
            !!this.evBillId || [1, 2, 3].indexOf(parseInt(eventPricingData?.state)) > -1;

        this.eventPricingData = eventPricingData;

        this.modelBean.canCreatePricingSet && (await this.modelBean.getPricingSets());

        if (this.evBillId) {
            this.modelBean.chosenPricingSet = this.modelBean.pricingSets.find((set: any) => set.id === this.evBillId);
        } else {
            this.modelBean.chosenPricingSet = this.modelBean.defaultPricingSet;
        }

        this.modelBean.dateTimeFormat = dateTimeFormat;
        this.modelBean.dateFormat = dateFormat;

        this.dateTimeFormat = dateTimeFormat;
        this.eventIds = eventPricingData?.eventIds;
        this.eventsNeedingRefresh = eventPricingData?.eventsNeedingRefresh;
        const relatedEventsNode = S25Util.propertyGet(eventPricingData, "content");
        //when combined, event data has no "content" tag bc all data has been merged into one "event" for combined pricing
        //so we always show the checkbox if combineRelatedEvents is true else users wont be able to uncheck it
        this.optBean.hasCombineRelatedEventsCheckbox =
            this.optBean.combineRelatedEvents || relatedEventsNode?.length > 0;

        this.optBean.selectedView = this.selectedView;

        const billItems = S25Util.propertyGet(eventPricingData, "bill_item") || [];
        const allOrgs = S25Util.propertyGet(eventPricingData, "organization") || [];
        const billedOrgs = S25Util.propertyGetAllUnique(eventPricingData, "charge_to_id")?.map(S25Util.toInt);
        const invoiceOrgs = S25Util.propertyGetAllUnique(invoiceData, "organization_id")?.map(S25Util.toInt);

        this.lang = lang?.div?.controls["s25-event_details"]?.text;
        this.canEditPricing =
            fls?.EVENT_BILLING === "F" &&
            (editAccess === "F" || isScheduler) &&
            ["F", "C", "R"].indexOf(fls?.CU_RATES) > -1;

        this.datePickerBean = {
            dateFormat: dateFormat,
            date:
                new Date(
                    S25Util.date.dropTZString(
                        S25Util.propertyGet(
                            S25Util.propertyGetParentWithChildValue(eventPricingData, "history_type_id", 4),
                            "history_dt",
                        ),
                    ),
                ) || new Date(),
            showToday: true,
        };

        this.orgSummaryBean = {
            billedOrgs: billedOrgs,
            invoiceOrgs: invoiceOrgs,
            allOrgs: allOrgs,
            eventId: this.itemId,
            hasFreshbooks: hasFreshbooks,
            evBillId: this.evBillId,
            lang: this.lang,
            invoices: this.modelBean.pricingSets,
        };

        this.eventRateGroupModel = {
            rateGroups: rateGroups,
            eventId: this.itemId,
            canEdit: true,
            canEditPricing: this.canEditPricing,
            isEventLevel: true,
            evBillId: this.evBillId,
            fls: fls,
            eventData: eventPricingData,
        };

        jSith.forEach(allOrgs, (_, org) => {
            org.isBillingOrg = billedOrgs.indexOf(parseInt(org.organization_id)) > -1;
            if (org.isBillingOrg) {
                const orgListData = PricingService.getPricingOrganizationListModelData(
                    eventPricingData,
                    this.itemId,
                    parseInt(org.organization_id),
                    rateGroups,
                    taxesMap,
                    this.canEditPricing,
                );

                orgListData.combineRelatedEvents = !!this.optBean.combineRelatedEvents; //used in pricing-service to create/delete reference column...
                orgListData.fls = fls;
                orgListData.dateFormat = dateFormat;
                orgListData.occSubtotals = {};
                eventPricingData.occSubtotals?.forEach((item: AccountOccurrenceSubtotal) => {
                    if (item.chargeToId === org.organization_id) {
                        orgListData.occSubtotals[item.rsrvId] = item;
                    }
                });

                orgListData.allOccurrences = {
                    dateFormat,
                    dateTimeFormat,
                    timeFormat,
                };

                orgListData.idToBillName = this.idToBillName;

                eventPricingData.occurrences?.forEach((item: Occurrence) => {
                    orgListData.allOccurrences[item.rsrvId] = item;
                });

                orgListData.profileSubtotals = [];
                eventPricingData.profileSubtotals?.forEach((item: AccountProfileSubTotal) => {
                    if (item.chargeToId === org.organization_id) {
                        orgListData.profileSubtotals.push(item);
                    }
                });
                orgListData.hideNoChargeItems = this.optBean.hideNoChargeItems;
                const orgInList = S25Util.array.getByProp(this.orgLists, "orgId", org.organization_id);

                if (orgInList) {
                    //organization list already exists, just reset its getdata function
                    //and set some other info, mainly subTotal sub-section so that grand totals are updated...
                    //and hasEventCharges so create invoice btn shows if charges were added
                    Object.assign(orgInList, {
                        data: orgListData,
                        rowFuncs: PricingService.getPricingOrganizationListFn(orgListData),
                        hasEventCharges:
                            PricingService.agg.totalCharge.total(
                                billItems.filter(PricingService.billItemOrgFilter(org.organization_id)),
                            ) > 0,
                        subTotal: PricingService.getSubTotalObject(org, orgListData?.allBillItems),
                        invoiceData: invoiceData,
                        evBillId: this.evBillId,
                    });
                } else {
                    //create organization lists
                    this.orgLists.push(
                        S25Util.merge(S25Util.deepCopy(this.commonListBean), {
                            hasFreshbooks: hasFreshbooks,
                            data: orgListData,
                            rowFuncs: PricingService.getPricingOrganizationListFn(orgListData),
                            orgId: org.organization_id,
                            orgName: (org.organization_name?.toString() || "").toUpperCase(),
                            hasEventCharges:
                                PricingService.agg.totalCharge.total(
                                    billItems.filter(PricingService.billItemOrgFilter(org.organization_id)),
                                ) > 0,
                            subTotal: PricingService.getSubTotalObject(org, orgListData?.allBillItems),
                            total: PricingService.agg.totalCharge.total(orgListData?.allBillItems),
                            invoiceData: invoiceData,
                            evBillId: this.evBillId,
                            canEdit: this.canEditPricing,
                        }),
                    );
                }
            }
        });

        this.orgLists.sort(S25Util.shallowSort("orgName"));

        const pricingTotalsModel: any = {
            evBillId: this.evBillId,
            taxesMap: taxesMap,
            grandTaxes: { tax: [] },
            rows: [],
        };

        jSith.forEach(this.orgLists, (_, org) => {
            //add flag on each org to indicate if related events are available
            org.hasRelatedEvents = this.optBean.combineRelatedEvents || relatedEventsNode?.length;
            pricingTotalsModel.grandPriceList = (pricingTotalsModel.grandPriceList || 0) + org.subTotal.list_price; //accumulate list price from each org
            pricingTotalsModel.grandAdjustmentAmt =
                (pricingTotalsModel.grandAdjustmentAmt || 0) + org.subTotal.adjustment_amt; //accumulate adj amt from each org
            pricingTotalsModel.rows.push(org.subTotal); //place org sub total row in array
        });

        billItems?.forEach((item: any) => {
            if (parseInt(item.bill_item_type_id) === -1) {
                pricingTotalsModel.grandAdjustmentAmt += parseFloat(item.total_charge);

                pricingTotalsModel.rows.push({
                    evBillId: this.evBillId,
                    isAdjustment: true,
                    adjustment_name: item.adjustment_name,
                    total_charge: item.total_charge,
                    charge_to_name: item.charge_to_name,
                    charge_to_id: item.charge_to_id,
                    adjustment_amt: item.adjustment_amt,
                    adjustment_percent: item.adjustment_percent,
                });
            }
        });

        pricingTotalsModel.grandTaxableAmt = PricingService.agg.adjustedPrice.subtotal(billItems);
        pricingTotalsModel.grandTotalCharge = PricingService.agg.totalCharge.total(billItems);
        pricingTotalsModel.grandTaxes.tax = PricingService.agg.taxes.totalByTaxId(billItems, taxesMap);
        this.pricingTotalsModel = pricingTotalsModel;
        if (this.pricingTotalsList) {
            this.pricingTotalsList.getdata = PricingService.getPricingTotalsListFn(pricingTotalsModel);
        } else {
            this.pricingTotalsList = S25Util.merge(
                S25Util.copy(this.commonListBean, {
                    getdata: PricingService.getPricingTotalsListFn(pricingTotalsModel),
                }),
            );
        }
    }
    @Bind
    async refresh(totalsRefreshData?: any) {
        if (!this.optBean.combineRelatedEvents && totalsRefreshData) {
            this.totalsTable.initTableConfig(totalsRefreshData);
            return;
        }

        this.init = false;
        this.pricingInit = false;
        this.orgLists = [];
        this.cd.detectChanges();

        await this.ngOnInit();

        setTimeout(() => {
            this.cd && !(this.cd as ViewRef).destroyed && this.cd.detectChanges();
        });
    }

    @Bind
    async optRefresh(selectedView?: OptBean["selectedView"]) {
        this.selectedView = selectedView ?? this.orgsLists.selectedView;

        S25LoadingApi.init(this.elementRef.nativeElement);

        this.pricingInit = false;
        this.orgLists = [];
        this.cd.detectChanges();

        await this.initPricing();

        this.pricingInit = true;
        S25LoadingApi.destroy(this.elementRef.nativeElement);

        setTimeout(() => {
            this.cd && !(this.cd as ViewRef).destroyed && this.cd.detectChanges();
            this.updateTableColumns();
        });
    }

    updateAllRateGroups(tableData: any) {
        this.orgsLists.refreshAllRateGroups(tableData);
    }

    getColChooseBean() {
        const colList = [...PricingService.PricingConst.cols].map((obj: any) => {
            obj.isVisible = 1;
            return obj;
        });

        if (!this.optBean || !S25Util.coalesce(this.optBean.combineRelatedEvents, false)) {
            S25Util.array.inplaceRemoveByProp(colList, "prefname", "reference"); //no reference column if not combining related events...
        }

        return {
            //get list of columns available, set all to visible (checked)
            colList: colList,
            updateCallback: () => {
                this.updateTableColumns();
            },
        };
    }

    @Bind
    updateTableColumns() {
        this.orgsLists.updateColumns(this.optBean.colChooseBean.colList);
    }

    async updateDate() {
        try {
            await PricingService.putPricingBillingDate(
                this.itemId,
                this.datePickerBean,
                this.eventPricingData?.evBillId,
            );
        } catch (error) {
            console.error(error);
            alert("There was an error while updating the billing date");
        }
    }

    disableDeleteSet(evBillId: number) {
        if (evBillId === this.evBillId) {
            this.setDeleteDisabled = true;
            this.cd.detectChanges();
        }
    }

    togglePricingSet(set: Billing) {
        this.modelBean.chosenPricingSet = set;
        this.modelBean.selectPricingSet();
    }
}
