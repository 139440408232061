import { DataAccess } from "../../../dataaccess/data.access";
import { S25Util } from "../../../util/s25-util";
import { LoginService } from "../../../services/login.service";

export interface IPaymentDetails {
    paymentDetailId: number;
    paymentCreated?: string;
    paymentSuccess?: string;
    source: string;
    productName: string;
    productDescription: string;
    sessionId?: string;
    username?: string;
    instance?: string;
    evBillId?: number;
    paymentIntentId?: string;
    currency?: string;
    customerId?: string;
    status: string;
    type: "DEPOSIT" | "FINAL" | "MISC";
    paymentStatus: string;
    mode?: string;
    authorizationId?: string;
    created?: number;
    amountTotalCents: number;
    isVoid?: boolean;
    voidReason?: string;
    voidDate?: string;
    voidUsername?: string;
    dueDate?: string;
}

export interface PaymentData {
    totalPayments: number;
    remainingBalance: number;
    data: IPaymentDetails[];
}

export interface ICheckoutRequest {
    productName?: string;
    productDescription: string;
    currency: string;
    amountInCents: number;
    successUrl: string;
    cancelUrl: string;
    evBillId: number;
    organizationId: number;
    eventId: number;
    type: string;
    source: string;
    replacementId: number;
    dueDate: string;
    paymentStatus: "paid" | "unpaid";
}

export class PaymentService {
    public static async getAllPayments(evBillId: number): Promise<IPaymentDetails[] | null> {
        return DataAccess.get(
            DataAccess.injectCaller("/payments/bill/${evBillId}.json", "PaymentService.getAllPayments"),
        ).then(
            (payments) => {
                return payments;
            },
            (error) => {
                console.error(error);
                return null;
            },
        );
    }

    public static async getPayments(evBillId: number, orgId: number): Promise<IPaymentDetails[] | null> {
        return await DataAccess.get(
            DataAccess.injectCaller(
                "/payments/organization/" + orgId + "/bill/" + evBillId + ".json",
                "PaymentService.getPayments",
            ),
        ).then(
            (payments) => {
                return payments;
            },
            (error) => {
                console.error(error);
                return null;
            },
        );
    }

    public static async createPayment(payload: ICheckoutRequest): Promise<IPaymentDetails> {
        return await DataAccess.post(
            DataAccess.injectCaller("/payments/source/payment.json", "PaymentService.createPayment"),
            payload,
        );
    }

    public static async setPaid(paymentDetailId: number) {
        let login = await LoginService.getLoginCached();
        let username = encodeURIComponent(login.userName);
        return DataAccess.put(
            DataAccess.injectCaller(
                `/payments/success/${paymentDetailId}.json?customerId=${username}`,
                "PaymentService.setPaid",
            ),
        );
    }

    public static async voidPayment(paymentDetailId: number, reason: string) {
        try {
            return await DataAccess.delete(
                DataAccess.injectCaller(
                    "/payments/" + paymentDetailId + "/payment.json?reason=" + encodeURIComponent(reason),
                    "PaymentService.voidPayment",
                ),
            );
        } catch (e) {
            S25Util.showError(e);
            return null;
        }
    }

    public static async getFormattedPayments(evBillId: number, orgId: number, total: number): Promise<PaymentData> {
        try {
            const paymentDetails = await PaymentService.getPayments(evBillId, orgId);
            const totalPayments =
                paymentDetails?.reduce(
                    (sum: number, payment: IPaymentDetails) =>
                        sum + (payment.isVoid ? 0 : payment.amountTotalCents ?? 0),
                    0,
                ) ?? 0;

            return {
                totalPayments: totalPayments,
                remainingBalance: (+total * 100 - totalPayments) / 100,
                data: paymentDetails,
            };
        } catch (e) {
            S25Util.showError(e);
            return null;
        }
    }
}
